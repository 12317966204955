import { auth, db } from '../lib/firebase';
import { doc, getDoc, setDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import toast from 'react-hot-toast';

export async function deductCredit(): Promise<boolean> {
  const user = auth.currentUser;
  if (!user) {
    toast.error('Please sign in to generate images');
    return false;
  }

  try {
    const userRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userRef);
    
    const isAdmin = user.email === 'qimi.work@gmail.com';

    // Admin users bypass credit check
    if (isAdmin) return true;

    const userData = userDoc.exists() ? userDoc.data() : null;

    if (!userData) {
      // Initialize new user with default credits
      await setDoc(userRef, {
        email: user.email,
        displayName: user.displayName || user.email?.split('@')[0],
        role: 'one-time-credit',
        creditsAvailable: 250,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        totalImagesGenerated: 0,
        lastImageGenerated: null,
        isActive: true
      });
      return true;
    }

    // Check available credits
    const currentCredits = userData.creditsAvailable || 0;
    if (currentCredits <= 0) {
      toast.error('No credits available. Please upgrade your plan or purchase more credits.');
      return false;
    }

    // Handle subscription users
    if (userData.role === 'subscription') {
      const now = new Date();
      const renewalDate = userData.subscriptionRenewalDate ? new Date(userData.subscriptionRenewalDate) : null;

      if (renewalDate && now >= renewalDate) {
        // Reset credits on renewal
        await updateDoc(userRef, {
          creditsAvailable: 300,
          subscriptionRenewalDate: new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000).toISOString(),
          updatedAt: serverTimestamp()
        });
        return true;
      }
    }

    // Deduct credit
    await updateDoc(userRef, {
      creditsAvailable: currentCredits - 1,
      lastImageGenerated: serverTimestamp(),
      totalImagesGenerated: (userData.totalImagesGenerated || 0) + 1,
      updatedAt: serverTimestamp()
    });

    return true;
  } catch (error) {
    console.error('Credit deduction failed:', error);
    const errorMessage = error instanceof Error ? error.message : 'Failed to process credits';
    toast.error(errorMessage);
    return false;
  }
}