import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { ThemeProvider } from './components/ThemeProvider';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './lib/firebase';
import KidsLayout from './pages/kids/KidsLayout';
import AdultLayout from './pages/adult/AdultLayout';
import CozyLayout from './pages/cozy/CozyLayout';
import DoodleLayout from './pages/doodle/DoodleLayout';
import ContentLayout from './pages/content/ContentLayout';
import Dashboard from './pages/Dashboard';
import { LoginPage } from './pages/LoginPage';
import { SignupPage } from './pages/SignupPage';
import { AccountSettings } from './pages/AccountSettings';
import { AdminRoute } from './components/AdminRoute';
import { UnauthorizedPage } from './pages/UnauthorizedPage';
import { DashboardLayout } from './components/layout/DashboardLayout';
import AdminPortal from './pages/admin/AdminPortal';
import UserManagement from './pages/admin/UserManagement';
import SystemSettings from './pages/admin/SystemSettings';
import CreditManagement from './pages/admin/CreditManagement';
import PaymentPage from './pages/PaymentPage';
import PaymentSuccess from './pages/PaymentSuccess';
import Tutorial from './pages/Tutorial';

// Import all layouts and pages...
// [Previous imports remain the same]

export function App() {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <>
      <ThemeProvider>
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup/one-time" element={<SignupPage type="one-time" />} />
        <Route path="/signup/subscription" element={<SignupPage type="subscription" />} />
        <Route path="/unauthorized" element={<UnauthorizedPage />} />
        <Route path="/payment" element={<PaymentPage />} />
        <Route path="/payment/success" element={<PaymentSuccess />} />

        {/* Protected Routes */}
        <Route path="/" element={<DashboardLayout />}>
          <Route index element={<Dashboard />} /> 
          
          {/* Category Routes */}
          <Route path="kids/*" element={<KidsLayout />} />
          <Route path="adult/*" element={<AdultLayout />} />
          <Route path="cozy/*" element={<CozyLayout />} />
          <Route path="doodle/*" element={<DoodleLayout />} />
          <Route path="content/*" element={<ContentLayout />} />
          <Route path="tutorial" element={<Tutorial />} />
          
          <Route path="account" element={<AccountSettings />} />
          
          {/* Admin Routes */}
          <Route path="admin" element={<AdminRoute><AdminPortal /></AdminRoute>} />
          <Route path="admin/users" element={<AdminRoute><UserManagement /></AdminRoute>} />
          <Route path="admin/settings" element={<AdminRoute><SystemSettings /></AdminRoute>} />
          <Route path="admin/credits" element={<AdminRoute><CreditManagement /></AdminRoute>} />
        </Route>

        {/* Catch all route */}
        <Route path="*" element={<Navigate to={user ? "/" : "/unauthorized"} replace />} />
      </Routes>
      <Toaster position="top-center" />
      </ThemeProvider>
    </>
  );
}

export default App;