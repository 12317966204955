import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { CategoryLayout } from '../../components/layout/CategoryLayout';
import { PenTool, Quote, Type, Shapes, Flower2 } from 'lucide-react';
import DoodlePattern from './DoodlePattern';
import QuotesDoodle from './QuotesDoodle';
import CursiveArt from './CursiveArt';
import DecorativeText from './DecorativeText';
import FloralPatterns from './FloralPatterns';
import DoodleAnything from './DoodleAnything';

const tools = [
  {
    id: 'pattern',
    title: 'Doodle Pattern',
    description: 'Generate seamless doodle patterns',
    icon: PenTool,
    path: 'pattern'
  },
  {
    id: 'quotes',
    title: 'Quotes + Doodle',
    description: 'Create inspiring quote designs',
    icon: Quote,
    path: 'quotes'
  },
  {
    id: 'cursive',
    title: 'Cursive Art',
    description: 'Transform words into beautiful designs',
    icon: Type,
    path: 'cursive'
  },
  {
    id: 'decorative',
    title: 'Decorative Text',
    description: 'Design beautiful text arrangements',
    icon: Shapes,
    path: 'decorative'
  },
  {
    id: 'floral',
    title: 'Floral Patterns',
    description: 'Create beautiful floral designs',
    icon: Flower2,
    path: 'floral'
  },
  {
    id: 'anything',
    title: 'Doodle Anything',
    description: 'Create charming doodle patterns',
    icon: PenTool,
    path: 'anything'
  }
];

function DoodleLayout() {
  return (
    <CategoryLayout
      title="Doodle & Art"
      description="Creative patterns and artistic designs"
      icon={PenTool}
      color="teal"
      tools={tools}
    >
      <Routes>
        <Route path="pattern" element={<DoodlePattern />} />
        <Route path="quotes" element={<QuotesDoodle />} />
        <Route path="cursive" element={<CursiveArt />} />
        <Route path="decorative" element={<DecorativeText />} />
        <Route path="floral" element={<FloralPatterns />} />
        <Route path="anything" element={<DoodleAnything />} />
      </Routes>
    </CategoryLayout>
  );
}

export default DoodleLayout;