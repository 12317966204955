import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, doc, deleteDoc } from 'firebase/firestore';
import { db, auth } from '../../lib/firebase';
import { UserCircle, Shield, Trash2, Lock } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { AdminService } from '../../services/adminService';
import toast from 'react-hot-toast';

interface User {
  id: string;
  email: string;
  displayName?: string;
  role: string;
  creditsAvailable: number;
}

export function UserList() {
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSendingReset, setIsSendingReset] = useState(false);
  const [isUpdatingRole, setIsUpdatingRole] = useState<string | null>(null);
  const [isSyncing, setIsSyncing] = useState(false);

  const handleSyncUsers = async () => {
    setIsSyncing(true);
    try {
      const idToken = await auth.currentUser?.getIdToken();
      if (!idToken) {
        throw new Error('Not authenticated');
      }

      const response = await fetch('/.netlify/functions/sync-auth-users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to sync users');
      }

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Failed to sync users');
      }
      
      toast.success(`Successfully synced ${data.syncedCount} users`);
    } catch (error) {
      console.error('Error syncing users:', error);
      toast.error('Failed to sync users');
    } finally {
      setIsSyncing(false);
    }
  };

  useEffect(() => {
    const currentUser = auth.currentUser;
    if (!currentUser || currentUser.email !== 'qimi.work@gmail.com') {
      navigate('/');
      return;
    }

    let mounted = true;
    const unsubscribe = onSnapshot(
      collection(db, 'users'),
      (snapshot) => {
        const userData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        })) as User[];
        setUsers(userData);
      },
      (error) => {
        console.error('Error fetching users:', error);
        if (error.code === 'permission-denied') {
          toast.error('You do not have permission to view user data');
          navigate('/');
        } else {
          toast.error('Failed to load users');
        }
      },
    );

    return () => {
      mounted = false;
      unsubscribe();
    };
  }, [navigate, auth.currentUser?.email]);

  const handleUpdateRole = async (userId: string, newRole: string) => {
    const user = users.find(u => u.id === userId);
    if (!user) return;
    
    if (!auth.currentUser?.email) {
      toast.error('Authentication required');
      return;
    }

    const previousRole = user.role;
    setIsUpdatingRole(userId);

    try {
      const response = await fetch('/.netlify/functions/update-user-role', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-user-email': auth.currentUser.email
        },
        body: JSON.stringify({
          userId,
          role: newRole,
        }),
      });

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || data.message || 'Failed to update user role');
      }

      // Update local state with server response
      setUsers(prevUsers =>
        prevUsers.map(u =>
          u.id === userId ? { ...u, ...data.updates } : u
        )
      );

      toast.success('User role updated successfully');
    } catch (error) {
      console.error('Error updating role:', error);
      
      toast.error(error instanceof Error ? error.message : 'Failed to update user role');
    } finally {
      setIsUpdatingRole(null);
    }
  };

  const handleDeleteUser = async (userId: string, email: string) => {
    if (!confirm(`Are you sure you want to delete user ${email}?`)) {
      return;
    }

    setIsDeleting(true);
    try {
      const response = await fetch('/.netlify/functions/delete-user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to delete user');
      }

      await deleteDoc(doc(db, 'users', userId));
      setUsers(prevUsers => prevUsers.filter(user => user.id !== userId));
      setSelectedUsers(prev => prev.filter(id => id !== userId));
      
      toast.success('User deleted successfully');
    } catch (error) {
      console.error('Error deleting user:', error);
      toast.error(error instanceof Error ? error.message : 'Failed to delete user');
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSendPasswordReset = async (email: string) => {
    setIsSendingReset(true);
    try {
      const response = await fetch('/.netlify/functions/send-password-reset', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to send password reset');
      }

      toast.success('Password reset email sent successfully');
    } catch (error) {
      console.error('Error sending password reset:', error);
      toast.error(error instanceof Error ? error.message : 'Failed to send password reset');
    } finally {
      setIsSendingReset(false);
    }
  };

  return (
    <div className="overflow-x-auto">
      <div className="mb-4 flex justify-end">
        <button
          onClick={handleSyncUsers}
          disabled={isSyncing}
          className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
        >
          {isSyncing ? (
            <>
              <Shield className="w-5 h-5 animate-spin" />
              Syncing...
            </>
          ) : (
            <>
              <Shield className="w-5 h-5" />
              Sync Auth Users
            </>
          )}
        </button>
      </div>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              <input
                type="checkbox"
                checked={selectedUsers.length === users.length}
                onChange={() => {
                  setSelectedUsers(
                    selectedUsers.length === users.length ? [] : users.map(u => u.id)
                  );
                }}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              User
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Role
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {users.map((user) => (
            <tr key={user.id}>
              <td className="px-6 py-4">
                <input
                  type="checkbox"
                  checked={selectedUsers.includes(user.id)}
                  onChange={() => {
                    setSelectedUsers(prev =>
                      prev.includes(user.id)
                        ? prev.filter(id => id !== user.id)
                        : [...prev, user.id]
                    );
                  }}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <UserCircle className="h-8 w-8 text-gray-400" />
                  </div>
                  <div className="ml-4">
                    <div className="text-sm font-medium text-gray-900">
                      {user.displayName || 'No Name'}
                    </div>
                    <div className="text-sm text-gray-500">{user.email}</div>
                  </div>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <select
                  value={user.role}
                  onChange={(e) => handleUpdateRole(user.id, e.target.value)}
                  disabled={isUpdatingRole === user.id}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:bg-gray-100"
                >
                  <option value="one-time-credit">One Time Credit (250 credits)</option>
                  <option value="subscription">Subscription (300 credits/month)</option>
                  <option value="unlimited">Unlimited</option>
                </select>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => handleSendPasswordReset(user.email)}
                    disabled={isSendingReset}
                    className="text-blue-600 hover:text-blue-900 disabled:opacity-50"
                    title="Send password reset email"
                  >
                    <Lock className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => handleDeleteUser(user.id, user.email)}
                    disabled={isDeleting}
                    className="text-red-600 hover:text-red-900 disabled:opacity-50"
                    title="Delete user"
                  >
                    <Trash2 className="w-5 h-5" />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}