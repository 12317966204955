import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, updateDoc, increment, serverTimestamp } from 'firebase/firestore';
import { db, auth } from '../lib/firebase';
import { CheckCircle, Loader, AlertCircle } from 'lucide-react';
import toast from 'react-hot-toast';

export default function PaymentSuccess() {
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);

  useEffect(() => {
    async function processPayment() {
      const user = auth.currentUser;
      if (!user) {
        toast.error('Please sign in to process payment');
        navigate('/login');
        return;
      }

      try {
        const searchParams = new URLSearchParams(window.location.search);
        const creditAmount = Number(searchParams.get('credits')) || 150; // Default to 150 if not specified
        
        const userRef = doc(db, 'users', user.uid);
        
        // Update user's credits
        await updateDoc(userRef, {
          creditsAvailable: increment(creditAmount),
          lastTopUp: serverTimestamp(),
          updatedAt: serverTimestamp()
        });

        toast.success(`${creditAmount} credits have been added to your account!`);
        setIsProcessing(false);
        
        // Redirect to dashboard after 2 seconds
        setTimeout(() => navigate('/'), 2000);
      } catch (error) {
        console.error('Error processing payment:', error);
        setError('Failed to process payment. Please contact support.');
        setIsProcessing(false);
      }
    }

    processPayment();
  }, [navigate]);

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-8 text-center">
        {error ? (
          <>
            <AlertCircle className="w-16 h-16 text-red-500 mx-auto mb-4" />
            <h2 className="text-2xl font-bold text-gray-900 mb-2">Payment Error</h2>
            <p className="text-gray-600">{error}</p>
          </>
        ) : isProcessing ? (
          <>
            <Loader className="w-16 h-16 text-blue-600 animate-spin mx-auto mb-4" />
            <h2 className="text-2xl font-bold text-gray-900 mb-2">Processing Payment</h2>
            <p className="text-gray-600">Please wait while we add credits to your account...</p>
          </>
        ) : (
          <>
            <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-4" />
            <h2 className="text-2xl font-bold text-gray-900 mb-2">Payment Successful!</h2>
            <p className="text-gray-600">Your credits have been added to your account.</p>
          </>
        )}
      </div>
    </div>
  );
}