import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../lib/firebase';
import { CreditService } from '../../services/creditService';
import { signOut } from 'firebase/auth';
import { ThemeToggle } from '../ThemeToggle';
import { 
  Palette, Brain, Heart, PenTool, Lightbulb,
  Settings, LogOut, Menu, X, Shield,
  CreditCard, Wallet, Book
} from 'lucide-react';
import toast from 'react-hot-toast';

const menuItems = [
  {
    id: 'kids',
    label: 'Kids Corner',
    icon: Palette,
    color: 'blue',
    path: '/kids',
    basePath: '/kids'
  },
  {
    id: 'adult',
    label: 'Adult Coloring',
    icon: Brain,
    color: 'purple',
    path: '/adult',
    basePath: '/adult'
  },
  {
    id: 'cozy',
    label: 'Cozy Collection',
    icon: Heart,
    color: 'red',
    path: '/cozy',
    basePath: '/cozy'
  },
  {
    id: 'doodle',
    label: 'Doodle & Art',
    icon: PenTool,
    color: 'teal',
    path: '/doodle',
    basePath: '/doodle'
  },
  {
    id: 'content',
    label: 'Content Ideas',
    icon: Lightbulb,
    color: 'yellow',
    path: '/content',
    basePath: '/content'
  },
  {
    id: 'payment',
    label: 'Top Up Credits',
    icon: Wallet,
    color: 'green',
    path: '/payment',
    basePath: '/payment'
  },
  {
    id: 'tutorial',
    label: 'Tutorial',
    icon: Book,
    color: 'blue',
    path: '/tutorial',
    basePath: '/tutorial'
  },
];

export function Sidebar() {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [credits, setCredits] = useState<number>(0);

  React.useEffect(() => {
    if (!user) return;

    const unsubscribe = CreditService.subscribeToCredits(user.uid, {
      onUpdate: (newCredits) => setCredits(newCredits),
      onError: (error) => {
        console.error('Error fetching credits:', error);
        toast.error('Failed to load credits');
      }
    });

    return () => {
      unsubscribe();
      setCredits(0);
    };
  }, [user]);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      toast.success('Signed out successfully');
      navigate('/login');
    } catch (error) {
      console.error('Sign out error:', error);
      toast.error('Failed to sign out');
    }
  };

  const isActive = (basePath: string) => location.pathname.startsWith(basePath);

  const handleNavigation = (path: string) => {
    navigate(path);
    setIsMobileMenuOpen(false);
  };

  return (
    <>
      {/* Mobile Menu Button */}
      <div className="fixed top-0 left-0 right-0 z-50 bg-white lg:hidden">
        <div className="px-4 py-3 flex items-center justify-between border-b">
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="p-2 rounded-lg hover:bg-gray-100"
          >
            {isMobileMenuOpen ? (
              <X className="w-6 h-6" />
            ) : (
              <Menu className="w-6 h-6" />
            )}
          </button>
          <div className="flex items-center gap-2">
            <Palette className="w-6 h-6 text-blue-600" />
            <span className="font-bold text-lg">Coloring AI</span>
          </div>
          <div className="w-10" /> {/* Spacer for alignment */}
        </div>
      </div>

      {/* Sidebar */}
      <aside className={`
        fixed inset-y-0 left-0 z-40 w-64 bg-white dark:bg-gray-800 border-r border-gray-200 dark:border-gray-700
        transition-transform duration-300 ease-in-out transform
        lg:translate-x-0 lg:sticky lg:top-0 lg:h-screen
        ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'}
      `}>
        <div className="flex flex-col h-full">
          {/* Logo */}
          <div className="p-4 border-b border-gray-200 dark:border-gray-700">
            <div className="flex items-center gap-2">
              <Palette className="w-8 h-8 text-blue-600" />
              <span className="font-bold text-xl text-gray-900 dark:text-white">Coloring AI</span>
            </div>
          </div>

          {/* Credits Display */}
          <div className="px-4 py-3 border-b border-gray-200 dark:border-gray-700">
            <div className="flex items-center justify-between px-4 py-2 bg-blue-50 dark:bg-blue-900/20 text-blue-600 dark:text-blue-400 rounded-lg">
              <div className="flex items-center gap-2">
                <CreditCard className="w-5 h-5" />
                <span className="font-medium">{credits} Credits</span>
              </div>
              <button
                onClick={() => handleNavigation('/payment')}
                className="text-sm hover:text-blue-800 transition-colors"
              >
                Top Up
              </button>
            </div>
          </div>

          {/* Navigation Menu */}
          <nav className="flex-1 px-4 py-4 space-y-2 overflow-y-auto sidebar-scrollbar">
            {menuItems.map((item) => (
              <button
                key={item.id}
                onClick={() => handleNavigation(item.path)}
                className={`
                  w-full flex items-center gap-3 px-4 py-3 rounded-lg transition-colors
                  ${location.pathname.startsWith(item.basePath)
                    ? `bg-${item.color}-50 dark:bg-${item.color}-900/20 text-${item.color}-600 dark:text-${item.color}-400`
                    : 'text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700'
                  }
                `}
              >
                <item.icon className="w-5 h-5 flex-shrink-0" />
                <span className="font-medium">{item.label}</span>
              </button>
            ))}
          </nav>

          {/* Bottom Actions */}
          <div className="p-4 border-t border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 space-y-2 mt-auto">
            {/* Account & Settings */}
            <button
              onClick={() => handleNavigation('/account')}
              className="w-full flex items-center gap-3 px-4 py-2 text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800 rounded-lg"
            >
              <Settings className="w-5 h-5" />
              <span className="font-medium">Account & Settings</span>
            </button>

            <div className="flex items-center justify-between px-4 py-2">
              <span className="text-sm text-gray-600 dark:text-gray-400">Theme</span>
              <ThemeToggle />
            </div>

            {/* Admin Portal */}
            {user?.email === 'qimi.work@gmail.com' && (
              <button
                onClick={() => handleNavigation('/admin/users')}
                className="w-full flex items-center gap-3 px-4 py-2 text-blue-600 hover:bg-blue-50 rounded-lg"
              >
                <Shield className="w-5 h-5" />
                <span className="font-medium">Admin Portal</span>
              </button>
            )}

            {/* Tutorial */}
            <button
              onClick={() => handleNavigation('/tutorial')}
              className="w-full flex items-center gap-3 px-4 py-2 text-gray-600 hover:bg-gray-50 rounded-lg"
            >
              <Book className="w-5 h-5" />
              <span className="font-medium">Tutorial</span>
            </button>

            {/* Sign Out */}
            <button
              onClick={handleSignOut}
              className="w-full flex items-center gap-3 px-4 py-2 text-red-600 hover:bg-red-50 rounded-lg"
            >
              <LogOut className="w-5 h-5" />
              <span className="font-medium">Sign Out</span>
            </button>
          </div>
        </div>
      </aside>

      {/* Mobile Overlay */}
      {isMobileMenuOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-30 lg:hidden"
          onClick={() => setIsMobileMenuOpen(false)}
        />
      )}
    </>
  );
}