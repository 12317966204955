import { doc, updateDoc, increment, onSnapshot, Unsubscribe, setDoc } from 'firebase/firestore';
import { auth, db } from '../lib/firebase';
import toast from 'react-hot-toast';

export interface CreditListener {
  onUpdate: (credits: number) => void;
  onError: (error: Error) => void;
}

export class CreditService {
  private static listeners: Map<string, CreditListener[]> = new Map();
  private static unsubscribers: Map<string, Unsubscribe> = new Map();

  static subscribeToCredits(userId: string, listener: CreditListener): () => void {
    // Add listener to array
    const userListeners = this.listeners.get(userId) || [];
    this.listeners.set(userId, [...userListeners, listener]);

    // Set up Firestore subscription if not already exists
    if (!this.unsubscribers.has(userId)) {
      const unsubscribe = onSnapshot(
        doc(db, 'users', userId),
        {
          next: (doc) => {
            if (doc.exists()) {
              const credits = doc.data()?.creditsAvailable ?? 0;
              this.notifyListeners(userId, credits);
            } else {
              // Create user document if it doesn't exist
              this.initializeUserDocument(userId);
            }
          },
          error: (error) => {
            this.notifyError(userId, error);
          }
        }
      );
      this.unsubscribers.set(userId, unsubscribe);
    }

    // Return cleanup function
    return () => {
      this.unsubscribeListener(userId, listener);
    };
  }

  private static async initializeUserDocument(userId: string) {
    try {
      const user = auth.currentUser;
      if (!user) {
        console.error('No authenticated user');
        return;
      }

      const userRef = doc(db, 'users', userId);
      
      const isAdmin = user.email && user.email === 'qimi.work@gmail.com';
      
      const initialData = {
        email: user.email,
        displayName: user.displayName || user.email.split('@')[0],
        role: isAdmin ? 'admin' : 'one-time-credit',
        creditsAvailable: isAdmin ? 999999 : 250,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        totalImagesGenerated: 0,
        lastImageGenerated: null,
        isActive: true
      };

      try {
        await setDoc(userRef, initialData, { merge: true });
        this.notifyListeners(userId, initialData.creditsAvailable);
      } catch (error) {
        console.error('Error setting user document:', error);
        throw error;
      }
    } catch (error) {
      console.error('Error initializing user document:', error);
      this.notifyError(userId, error instanceof Error ? error : new Error('Failed to initialize user document'));
    }
  }

  static async updateCredits(userId: string, amount: number): Promise<void> {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        creditsAvailable: increment(amount),
        updatedAt: new Date().toISOString()
      });
    } catch (error) {
      console.error('Error updating credits:', error);
      throw error;
    }
  }

  private static notifyListeners(userId: string, credits: number) {
    const listeners = this.listeners.get(userId) || [];
    listeners.forEach(listener => listener.onUpdate(credits));
  }

  private static notifyError(userId: string, error: Error) {
    const listeners = this.listeners.get(userId) || [];
    listeners.forEach(listener => listener.onError(error));
  }

  private static unsubscribeListener(userId: string, listenerToRemove: CreditListener) {
    const listeners = this.listeners.get(userId) || [];
    const updatedListeners = listeners.filter(l => l !== listenerToRemove);
    
    if (updatedListeners.length === 0) {
      // No more listeners, clean up Firestore subscription
      const unsubscribe = this.unsubscribers.get(userId);
      if (unsubscribe) {
        unsubscribe();
        this.unsubscribers.delete(userId);
      }
      this.listeners.delete(userId);
    } else {
      this.listeners.set(userId, updatedListeners);
    }
  }
}