import React, { useState } from 'react';
import { Book, Download, Play } from 'lucide-react';
import { VideoLightbox } from '../components/VideoLightbox';

interface Video {
  id: string;
  title: string;
  description: string;
}

interface Template {
  name: string;
  description: string;
  link: string;
}

export default function Tutorial() {
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);

  const templates: Template[] = [
    {
      name: "113 Title Template",
      description: "Perfect for creating eye-catching titles for your coloring books",
      link: "https://www.canva.com/design/DAGQ6D4e0JQ/tC6BDRZddJqSg69if7NCqQ/view?utm_content=DAGQ6D4e0JQ&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
    },
    {
      name: "8.5×8.5 inch Cover Template",
      description: "Professional cover template for your coloring book",
      link: "https://www.canva.com/design/DAGRLnDWTaI/9fNEKEg2h6GEu5Qxg9AtPg/view?utm_content=DAGRLnDWTaI&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
    },
    {
      name: "8.5×8.5 inch Book Template",
      description: "Interior pages template for your coloring book",
      link: "https://www.canva.com/design/DAGRLnA-Yu4/w7blrw0xB7uHLZi9MDSyAw/view?utm_content=DAGRLnA-Yu4&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
    }
  ];

  const videos: Video[] = [
    {
      id: "0vBvESrS1Kw",
      title: "Webinar Coloring Creator",
      description: "Learn how to create and publish your coloring books"
    },
    {
      id: "0UT5e09AeeA",
      title: "Research Tools",
      description: "Essential tools for market research and keyword analysis"
    },
    {
      id: "LRdXCfI_2oE",
      title: "Teknik Kajian Bonus",
      description: "Bonus research techniques for better results"
    },
    {
      id: "fzMLqtv9svc",
      title: "Teknik Kajian 1",
      description: "First part of the research methodology series"
    },
    {
      id: "7zu0FCnl8OY",
      title: "Teknik Kajian 2",
      description: "Second part of the research methodology series"
    },
    {
      id: "rbeVwkdDJ2o",
      title: "Teknik Kajian 3",
      description: "Third part of the research methodology series"
    },
    {
      id: "POw-c4aUTCw",
      title: "Teknik Kajian 4",
      description: "Fourth part of the research methodology series"
    }
  ];

  return (
    <div className="min-h-screen py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto space-y-8">
        <div className="text-center">
          <h1 className="text-3xl font-bold text-gray-900 dark:text-white">Tutorial & Resources</h1>
          <p className="mt-2 text-gray-600 dark:text-gray-400">Everything you need to get started with Coloring AI</p>
        </div>

        {/* Templates Section */}
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4 flex items-center gap-2">
            <Download className="w-5 h-5 text-blue-500" />
            Canva Templates
          </h2>
          <div className="grid gap-6">
            {templates.map((template, index) => (
              <div key={index} className="flex items-center justify-between p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
                <div>
                  <h3 className="font-medium text-gray-900 dark:text-white">{template.name}</h3>
                  <p className="text-sm text-gray-600 dark:text-gray-400">{template.description}</p>
                </div>
                <a
                  href={template.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                >
                  <Download className="w-4 h-4" />
                  Open in Canva
                </a>
              </div>
            ))}
          </div>
        </div>

        {/* Video Tutorial Section */}
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4 flex items-center gap-2">
            <Play className="w-5 h-5 text-blue-500" />
            Video Tutorials
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {videos.map((video) => (
              <div key={video.id} className="relative aspect-video rounded-lg overflow-hidden bg-gray-100 dark:bg-gray-700 group cursor-pointer" onClick={() => setSelectedVideo(video.id)}>
                <div className="absolute inset-0 flex items-center justify-center z-10">
                  <div className="w-16 h-16 flex items-center justify-center rounded-full bg-blue-600 group-hover:bg-blue-700 transition-colors">
                    <Play className="w-8 h-8 text-white" />
                  </div>
                </div>
                <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/80 to-transparent">
                  <h3 className="text-white font-medium">{video.title}</h3>
                  <p className="text-gray-200 text-sm">{video.description}</p>
                </div>
                <img
                  src={`https://img.youtube.com/vi/${video.id}/maxresdefault.jpg`}
                  alt={`${video.title} thumbnail`}
                  className="absolute inset-0 w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                />
              </div>
            ))}
          </div>
          
          {selectedVideo && (
            <VideoLightbox
              videoId={selectedVideo}
              onClose={() => setSelectedVideo(null)}
            />
          )}
        </div>
      </div>
    </div>
  );
}