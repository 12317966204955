import React from 'react';
import { Outlet, Routes, Route } from 'react-router-dom';
import { CategoryLayout } from '../../components/layout/CategoryLayout';
import { Brain, Flower2, Sparkles, Globe2, UserCircle2, Users } from 'lucide-react';
import MandalaArt from './MandalaArt';
import TarotCards from './TarotCards';
import MiniatureWorld from './MiniatureWorld';
import MysteryCharacters from './MysteryCharacters';
import QuarrelScene from './QuarrelScene';

const tools = [
  {
    id: 'mandala',
    title: 'Mandala Art',
    description: 'Create intricate mandala designs',
    icon: Flower2,
    path: 'mandala'
  },
  {
    id: 'tarot-cards',
    title: 'Tarot Cards',
    description: 'Design mystical tarot card illustrations',
    icon: Sparkles,
    path: 'tarot-cards'
  },
  {
    id: 'miniature-world',
    title: 'Miniature World',
    description: 'Create enchanting miniature scenes',
    icon: Globe2,
    path: 'miniature-world'
  },
  {
    id: 'mystery-characters',
    title: 'Mystery Characters',
    description: 'Design enigmatic character scenes',
    icon: UserCircle2,
    path: 'mystery-characters'
  },
  {
    id: 'quarrel-scene',
    title: 'Quarrel Scene',
    description: 'Create amusing disagreement scenes',
    icon: Users,
    path: 'quarrel-scene'
  }
];

function AdultLayout() {
  return (
    <CategoryLayout
      title="Adult Coloring"
      description="Complex and detailed designs for adults"
      icon={Brain}
      color="purple"
      tools={tools}
    >
      <Routes>
        <Route path="mandala" element={<MandalaArt />} />
        <Route path="tarot-cards" element={<TarotCards />} />
        <Route path="miniature-world" element={<MiniatureWorld />} />
        <Route path="mystery-characters" element={<MysteryCharacters />} />
        <Route path="quarrel-scene" element={<QuarrelScene />} />
      </Routes>
    </CategoryLayout>
  );
}

export default AdultLayout;