import React from 'react';
import { Outlet, Routes, Route } from 'react-router-dom';
import { CategoryLayout } from '../../components/layout/CategoryLayout';
import { 
  Palette, Ghost, Car, Cookie, Laugh, Bird, Zap 
} from 'lucide-react';
import SimpleEasy from './SimpleEasy';
import SuperheroName from './SuperheroName';
import MonsterFun from './MonsterFun';
import HappyVehicles from './HappyVehicles';
import FoodColoring from './FoodColoring';
import CircusClowns from './CircusClowns';
import FlyingBirds from './FlyingBirds';

const tools = [
  {
    id: 'simple',
    title: 'Simple & Easy',
    description: 'Create simple coloring pages',
    icon: Palette,
    path: 'simple'
  },
  {
    id: 'superhero-name',
    title: 'Superhero Name',
    description: 'Create personalized superhero coloring pages',
    icon: Zap,
    path: 'superhero-name'
  },
  {
    id: 'monster-fun',
    title: 'Monster Fun',
    description: 'Create whimsical monster scenes',
    icon: Ghost,
    path: 'monster-fun'
  },
  {
    id: 'happy-vehicles',
    title: 'Happy Vehicles',
    description: 'Design adorable vehicle characters',
    icon: Car,
    path: 'happy-vehicles'
  },
  {
    id: 'food-coloring',
    title: 'Cute Food',
    description: 'Create adorable food characters',
    icon: Cookie,
    path: 'food-coloring'
  },
  {
    id: 'circus-clowns',
    title: 'Circus Clowns',
    description: 'Design fun circus scenes',
    icon: Laugh,
    path: 'circus-clowns'
  },
  {
    id: 'flying-birds',
    title: 'Flying Birds',
    description: 'Create soaring bird scenes',
    icon: Bird,
    path: 'flying-birds'
  }
];

function KidsLayout() {
  return (
    <CategoryLayout
      title="Kids Corner"
      description="Simple and fun designs perfect for little ones"
      icon={Palette}
      color="blue"
      tools={tools}
    >
      <Routes>
        <Route path="simple" element={<SimpleEasy />} />
        <Route path="superhero-name" element={<SuperheroName />} />
        <Route path="monster-fun" element={<MonsterFun />} />
        <Route path="happy-vehicles" element={<HappyVehicles />} />
        <Route path="food-coloring" element={<FoodColoring />} />
        <Route path="circus-clowns" element={<CircusClowns />} />
        <Route path="flying-birds" element={<FlyingBirds />} />
      </Routes>
    </CategoryLayout>
  );
}

export default KidsLayout;